import React from 'react';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_list from '../../../../../../../common/src/assets/image/example_list.png';
import template_options_list from '../../../../../../../common/src/assets/image/template_options_list.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        The List template style generates a basic list of events or tasks for a
        specified date range, optionally including events or tasks that have no
        associated date.
      </p>
      <p>
        <Image alt="" src={example_list} />
      </p>
      <h2>Template Settings</h2>
      <p>
        The List template provides the following options when creating a calendar...
      </p>
      <Image alt="" src={template_options_list} style={{ width: 448 }} />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start date</strong>
            </td>
            <td>The first date to include in the output.</td>
          </tr>
          <tr>
            <td>
              <strong>Number of days</strong>
            </td>
            <td>The total number of days to include in the output.</td>
          </tr>
          <tr>
            <td>
              <strong>Time format</strong>
            </td>
            <td>
              The time format. Supported formats are <strong>12-hour</strong>{' '}
              (8:00 PM), <strong>24-hour</strong> (20:00),{' '}
              <strong>Shortened</strong> (8pm), <strong>Military </strong>
              (2000), and <strong>Numeric </strong>(20.0). An example time is
              shown for each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Include items without dates</strong>
            </td>
            <td>
              When checked, tasks without due dates will be included in the
              output.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Include completed items</strong>
            </td>
            <td>
              When checked, tasks that have already been completed will be
              included in the output.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Include overdue tasks </strong>
            </td>
            <td>
              When checked, any tasks that will be overdue before the specified
              start date will be listed, even if they were scheduled to be due
              on an earlier date.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/List"
      commentsId="commentsplus_post_172_489"
      title="List Template"
      description="Generate a basic list of events or tasks for a specified date range, optionally including events or tasks that have no associated date."
      keywords="event list, task list, list template"
      content={content}
    />
  );
};

export default Documentation;
